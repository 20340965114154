import React, { useEffect, useState,useRef  } from 'react'
import { useLocation } from 'react-router-dom'
import  Cookies  from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/loginSlice';
import logo from "../assets/logo1.png"
import expand from "../assets/expand.png"
import CircularProgress from '@mui/material/CircularProgress';
export default function Videos() {
    const dispatch = useDispatch()
    const [qrPath, setQrPath] = useState(undefined)
    const videoRef = useRef(null);
    const location  =useLocation();
    const [pageLoad, setPageLoad] = useState(true);
    let pathlength = location.pathname.split('/').length
    useEffect(()=>{
      document.title = `${location.pathname.split('/')[pathlength-1]} | studiorinternational`;
        if(Cookies.get("current_qr")){
            setQrPath(Cookies.get("current_qr"))
        }
        setPageLoad(false)
    },[])
    const handleLogout = ()=>{
        Cookies.remove("token")
        dispatch(logout())
    }
    const toggleFullScreen = () => {
        const video = videoRef.current;
        if (video) {
          if (video.requestFullscreen) {
            video.requestFullscreen();
          } else if (video.mozRequestFullScreen) { // Firefox
            video.mozRequestFullScreen();
          } else if (video.webkitRequestFullscreen) { // Chrome, Safari and Opera
            video.webkitRequestFullscreen();
          } else if (video.msRequestFullscreen) { // IE/Edge
            video.msRequestFullscreen();
          }
        }
      }
  return (
       <>
       {
        pageLoad ? 
        <div className='container-login100'>
          <div className='flex flex-col items-start justify-center  h-100vh'>
                        <div className='ml-10'><CircularProgress sx={{color:'white'}} /></div>
                        <p className="text-white text-2xl  text-semibold">Video Loading...</p>
                    </div>
        </div>  
        :
        <div className='bg-gradient'>
        <div className="bg-white ">
                <img src={logo} className='p-2 fixed bg-white' alt="" />
                {
                  Cookies.get("logout") === "false" ? 
                  null : 
                  <button onClick={handleLogout} className='btn text-white px-3 py-3 fixed right-0 bg-red-500 font-bold m-2 rounded-2xl '>Logout</button>
                }
                <button className='btn text-white px-3 py-3 fixed right-[5rem] bg-white font-bold m-2 rounded-2xl ' onClick={toggleFullScreen}><img src={expand} className='w-[24px]' alt="" /></button>
        </div>
        <div className="flex justify-center items-center  h-screen  ">
        <iframe src={qrPath} allowfullscreenallowfullscreen="true" allowtransparency="true" ref={videoRef} webkitallowfullscreen="true" mozallowfullscreen="true"  className=' w-[100%] md:w-[50%] md:h-[50%]  '  
        ></iframe>
                </div>
        
       </div>
       }
       </>
  )
}
