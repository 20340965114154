import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import Login from './Login';
import Videos from './Videos';
import { useLocation } from 'react-router-dom'
import  Cookies  from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logout,QRLogin } from '../redux/loginSlice';
import freePaths from '../assets/free_QR.json'
export default function Main() {
    const isSchool =  useSelector((state) => state.loginSlice.isLoggedIn);
    const location = useLocation();
    const [freeRoutes, setFreeRoutes] = useState()
    const dispatch = useDispatch()
    // Cookies.set("current_role",location.pathname)
    const fetchFree =  ()=>{
      let get = freePaths;
      if(get?.message.length>0){
        get?.message.map((e)=>{
          console.log(e)
          console.log(location.pathname)
          if(e === location.pathname){
            console.log("ch")
            let paths = localStorage.getItem("qrPaths");
            paths = JSON.parse(paths);
            console.log(paths)
            if(paths.length > 0){
              let x1=  paths.filter((item)=>item.route === location.pathname)
               let currentR = x1[0].route;
               Cookies.set("token", Math.floor(Math.random() * 10000000000).toString().padStart(10, '0'));
               Cookies.set("role",currentR);
               Cookies.set("pageName",x1[0].name)
               Cookies.set("current_role",currentR)
               Cookies.set("current_qr",`${x1[0].path}/index.html`)
               Cookies.set("logout","false")
               dispatch(QRLogin())
            }
          }
        })
      }
      setFreeRoutes(get.message)
    
  }
  useEffect(()=>{
    fetchFree();
  },[])
   
    if(location.pathname !== Cookies.get("current_role")){
        Cookies.remove("token")
        Cookies.remove("role")
        Cookies.remove("current_role")
        Cookies.remove("current_qr")
        dispatch(logout())
    }
  return (

        isSchool ? <Videos /> : <Login />
    
  )
}
