import React, { useEffect, useState } from 'react'
import { button, inputField } from '../helpers/Classes';
import Swal from 'sweetalert2';
import {  loginUser } from '../service/qrService';
import  Cookies  from 'js-cookie';
import { useDispatch } from 'react-redux';
import { QRLogin } from '../redux/loginSlice';
import { useLocation } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';

export default function Login() {

    const [email,setEmail] = useState(undefined);
    const [password,setPassword] = useState(undefined);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch(); 
    const location = useLocation();
    const [pageLoading, setPageLoading] = useState(true)
    
   

    useEffect(()=>{
       
        setPageLoading(false)
        document.title = 'Login | studiorinternational';
    },[]);

    const handleSubmit = async(e)=>{
        e.preventDefault()
        setLoading(true)
        if(email === undefined){
            return Swal.fire({
                icon: "error",
                text: "login Id required",
              });
        }
        if(password === undefined){
            return Swal.fire({
                icon: "error",
                text: "password required",
              });
        }

        let login = await loginUser(email,password);
        if(login.status === "error"){
            setLoading(false)
            return Swal.fire({
                icon: "error",
                text: login.message,
              });
        }
        setLoading(false)
        let paths = localStorage.getItem("qrPaths");
        paths = JSON.parse(paths);
        if(paths.length > 0){
           let x=  paths.filter((item)=>item.name === login.name)
           if(x.length ==0){
            return Swal.fire({
                icon: "error",
                text: "invalid user id and password",
              });
             
           }
        let x1=  paths.filter((item)=>item.route === location.pathname)
        console.log(x1)
           let currentR = x1[0].route;
           if(x1.length !== 1){
            return Swal.fire({
                icon: "error",
                text: "invalid user id and password",
              });
           }
           Cookies.set("token", login.token);
           Cookies.set("role",currentR);
           Cookies.set("pageName",x1[0].name)
           Cookies.set("current_role",currentR)
           Cookies.set("current_qr",`${x1[0].path}/index.html`)
           dispatch(QRLogin())
        }
    }

  return (
        <>
       
       
         <div className='container-login100'>
            
                {
                    pageLoading ? 
                    <div className='flex flex-col items-start justify-center  h-100vh'>
                        <div className='ml-5'><CircularProgress sx={{color:'white'}} /></div>
                        <p className="text-white text-2xl  text-semibold">Loading...</p>
                    </div>
                    :
                    <div>
                    <div className='bg-white rounded-3xl shadow-lg p-5 sm:p-5 md:p-10 lg:p-10 w-full'>
                        <p className=' text-2xl font-bold text-center'>Welcome to e-portal of Unisec Publication</p>
                        <p className=' font-semibold text-gray-500 mt-5 text-center'>Login to continue..</p>
                        <form className='my-5' onSubmit={handleSubmit}>
                        <div className="flex mb-2 flex-row gap-3 ">
                        <div className="flex flex-col mb-3 w-[100%]">
                            <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Email <sup className='text-red-600'>*</sup></label>
                            <input type="text"
                            required
                            onChange={((e)=>setEmail(e.target.value))}
                            className={`${inputField} `}  placeholder='Login Id'   />
                            </div>
                        </div>
                        <div className="flex mb-3 flex-row gap-3 ">
                        <div className="flex flex-col mb-3 w-[100%]">
                            <label className='text-gray-600 dark:text-gray-400 ml-1 ' >Password <sup className='text-red-600'>*</sup></label>
                            <input type="password" 
                            required
                            onChange={((e)=>setPassword(e.target.value))}
                            className={`${inputField} `}  placeholder='password'   />
                            </div>
                        </div>
                        <div className="flex mb-3 flex-row gap-3 ">
                        <div className="flex flex-col mb-3 w-[100%]">
                        <button disabled={loading ? true : false}  className={`${button} font-bold w-full`}>
                            {loading ? 'Logging...' : 'Login'}
                            </button>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            }
                
        </div>
        </>
      
  )
}
